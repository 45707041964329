import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify.js';
import vuesweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.css';

Vue.use(vuesweetalert2);

// Vue.config.productionTip = false; //**default true production  **recomended false

new Vue({
  name: 'SirketKayit',
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
