<template>
  <v-app>
    <v-container class="fill-height">
      <v-card class="col-md-10 offset-md-1 col-lg-8 offset-lg-2" elevation="12">
        <!-- <v-alert border="right" light class="text-center">
          <img style="width: 200px" src="https://sistem.xysinav.com/Resimler/Sirket/sinav.png" alt="" />
        </v-alert> -->
        <v-alert border="right" color="red darken-2" dark class="text-center">
          <h2>Şifremi Unuttum</h2>
        </v-alert>
        <v-expand-transition mode="out-in">
          <v-row justify="center" v-if="adim1">
            <v-col cols="10">
              <div class="text-center font-weight-bold">
                <h3>Kişisel Bilgiler</h3>
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" sm="10" md="10">
              <v-text-field
                label="E-posta"
                v-model="eposta"
                rounded
                outlined
                dense
                name="posta"
                append-icon="mdi-email"
                type="text"
                :rules="[rules.required, rules.email]"
            /></v-col>
            <v-col cols="10" sm="10" md="10">
              <v-text-field
                label="Telefon"
                v-model="telefon"
                rounded
                outlined
                dense
                name="tel"
                append-icon="mdi-cellphone"
                type="number"
                :hint="true ? 'Başında 0 olmadan yazınız' : ''"
                required
                counter="10"
                :rules="[rules.required, rules.counter]"
              />
            </v-col>
            <!-- <v-col cols="10" sm="10" md="5">
              <v-img
                :src="'data:image/jpeg;base64,' + captchaCode"
                width="200"
                class="mx-auto"
              />
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-text-field
                rounded
                outlined
                dense
                label="Doğrulama Kodu"
                append-icon="mdi-rotate-right"
                v-model="captcha"
              ></v-text-field
            ></v-col> -->
          </v-row>
        </v-expand-transition>
        <v-expand-transition mode="out-in">
          <v-row justify="center" v-if="adim2">
            <v-col cols="10">
              <div class="text-center font-weight-bold">
                <h3>Doğrulama İşlemi</h3>
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" sm="10" md="10">
              <v-chip class="mb-5" color="primary">
                Telefonunuza gelen kodu giriniz.
              </v-chip>
              <v-text-field
                label="Doğrulama Kodu"
                v-model="dogrulama"
                rounded
                outlined
                dense
                name="verifyCode"
                append-icon="mdi-cellphone-arrow-down"
                type="number"
              />
              <v-btn
                name="code"
                color="red darken-2"
                v-if="kodYok"
                dark
                @click="tekrarGonder"
                >Kod Gelmedi</v-btn
              >
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-expand-transition mode="out-in">
          <v-row justify="center" v-if="adim3">
            <v-col cols="10">
              <div class="text-center font-weight-bold">
                <h3>Yeni Şifre</h3>
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="10" sm="10" md="10">
              <v-chip class="mb-5" color="orange accent-4">
                <v-avatar left>
                  <v-icon>mdi-form-textbox-lock</v-icon>
                </v-avatar>
                Minimum 6 Karakterli Şifre Giriniz
              </v-chip>
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-text-field
                label="Yeni Şifre"
                rounded
                outlined
                dense
                name="pass"
                append-icon="mdi-form-textbox-password"
                type="password"
                v-model="sifre"
              />
            </v-col>
            <v-col cols="10" sm="10" md="5">
              <v-text-field
                label="Yeni Şifre Tekrar"
                rounded
                outlined
                dense
                name="rePass"
                append-icon="mdi-form-textbox-password"
                type="password"
                v-model="sifreTekrar"
              />
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="adim1"
            class="float-right"
            color="warning"
            @click="kodGonder"
            >Kod Gönder</v-btn
          >
          <v-btn
            v-if="adim2 && dogrulama.length == 4"
            class="float-right"
            color="warning"
            @click="sifreGir"
            >Şifre Gir</v-btn
          >
          <v-btn v-if="adim3" class="float-right" color="green" @click="Kaydet"
            >Kaydet</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'ForgotPasword',
  data() {
    return {
      adim1: true,
      adim2: false,
      adim3: false,
      kodYok: false,
      eposta: '',
      telefon: '',
      captcha: '',
      dogrulama: '',
      mongoId: '',
      sifre: '',
      sifreTekrar: '',
      rules: {
        required: (value) => !!value || 'Zorunlu Alan.',
        counter: (value) => value.length <= 10 || 'Maks. 10 Karakter',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Hatalı eposta girdiniz.';
        },
      },
    };
  },
  created() {
    this.$vuetify.theme.isDark = false;
    this.$store.dispatch('CAPTCHA_CODE');
  },
  methods: {
    async kodGonder() {
      if (this.eposta == '' || this.telefon == '') {
        this.$swal({
          icon: 'warning',
          title: 'Dikkat !',
          text: 'Boş Alan Bırakmaynız',
          confirmButtonText: 'Kapat',
        });
      } else {
        await this.$store
          .dispatch('FORGOT_CHECK', {
            kullaniciAdi: this.eposta,
            cepTelefonu: this.telefon,
          })
          .then(() => {
            if (
              this.forgotCheck.status == 400 ||
              this.forgotCheck.status == 500
            ) {
              if (
                this.forgotCheck.data.message ==
                'Telefon onayınız olmadığı için şifre sıfırlama yapılamıyor.'
              ) {
                this.$swal({
                  icon: 'error',
                  title: 'Hatalı İşlem',
                  html:
                    this.forgotCheck.data.message +
                    '</br>' +
                    'Telefon Onayı İçin Yönlendirileceksiniz.',
                  allowOutsideClick: false,
                  confirmButtonText: 'Yönlendir',
                }).then(() => {
                  this.$store.dispatch('FORGOT_RECHECK', {
                    mongoId: this.forgotCheck.data.data,
                  });
                  window.location =
                    'https://kayit.denemix.com.tr/confirm/' +
                    this.forgotCheck.data.data;

                  return 0;
                });
              } else {
                this.$swal({
                  icon: 'error',
                  title: 'Hatalı İşlem',
                  text: this.forgotCheck.data.message,
                  confirmButtonText: 'Kapat',
                });
              }
            } else {
              this.mongoId = this.forgotCheck.data.data;
              this.adim1 = false;
              this.adim2 = true;
              this.kodGelmedi();
            }
          });
      }
    },
    async sifreGir() {
      this.$store
        .dispatch('FORGOT_CHECK_CONTROL', {
          mongoId: this.mongoId,
          onayKodu: this.dogrulama,
        })
        .then(() => {
          if (
            this.forgotControl.status == 400 ||
            this.forgotControl.status == 500
          ) {
            this.$swal({
              icon: 'error',
              title: 'Hatalı İşlem',
              text: this.forgotControl.data,
              confirmButtonText: 'Kapat',
            });
          } else {
            this.adim2 = false;
            this.adim3 = true;
          }
        });
    },
    async Kaydet() {
      if (this.sifre.length <= 5) {
        this.$swal({
          icon: 'warning',
          title: 'Min. 6 karakter olarak',
          text: 'Şifre Giriniz',
          confirmButtonText: 'Kapat',
        });
      } else if (this.sifreTekrar.length <= 5) {
        this.$swal({
          icon: 'warning',
          title: 'Min. 6 karakter olarak',
          text: 'Şifre Giriniz',
          confirmButtonText: 'Kapat',
        });
      } else {
        this.$store
          .dispatch('FORGOT_SUBMIT_PASSWORD', {
            mongoId: this.mongoId,
            sifre: this.sifre,
            sifreTekrar: this.sifreTekrar,
          })
          .then(() => {
            if (
              this.forgotSubmit.status == 400 ||
              this.forgotSubmit.status == 500
            ) {
              this.$swal({
                icon: 'warning',
                title: 'Dikkat !',
                text: this.forgotSubmit.data,
                confirmButtonText: 'Kapat',
              });
            } else {
              this.$swal({
                icon: 'success',
                title: 'İşlem Başarılı',
                text: 'Şifreniz Güncellenmiştir',
                confirmButtonText: 'Kapat',
              }).then(() => {
                window.location = 'https://online.denemix.com.tr';
              });
            }
          });
      }
    },
    kodGelmedi() {
      setTimeout(() => {
        this.kodYok = true;
      }, 15000);
    },
    async tekrarGonder() {
      await this.$store
        .dispatch('FORGOT_RECHECK', {
          mongoId: this.mongoId,
        })
        .then(() => {
          if (
            this.forgotReChek.status == 400 ||
            this.forgotReChek.status == 500
          ) {
            this.$swal({
              icon: 'warning',
              title: 'Dikkat !',
              text: this.forgotReChek.data,
              confirmButtonText: 'Kapat',
            });
          } else {
            this.$swal({
              icon: 'success',
              title: 'İşlem Gönderildi',
              text: 'Telefonunuza yeni kod gönderilmiştir.',
              confirmButtonText: 'Kapat',
            });
          }
        });
    },
  },
  computed: {
    captchaCode() {
      return this.$store.getters.captchaSms;
    },
    captchaCap() {
      return this.$store.getters.captchaEncrypted;
    },
    forgotCheck() {
      return this.$store.getters.forgotCheck;
    },
    forgotControl() {
      return this.$store.getters.forgotControl;
    },
    forgotSubmit() {
      return this.$store.getters.forgotSubmit;
    },
    forgotReChek() {
      return this.$store.getters.forgotReChek;
    },
  },
};
</script>
