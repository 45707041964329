import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import signIn from '../SignIn.vue';
import confirm from '../ConfirmPage.vue';
import forgot from '../ForgotPassword.vue';

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '*',
      component: signIn,
    },
    {
      path: '/',
      name: 'SignIn',
      component: signIn,
    },
    {
      path: '/dogrula/:mongo',
      name: 'ConfirmPage',
      component: confirm,
    },
    {
      path: '/forgot/',
      name: 'ForgotPassword',
      component: forgot,
    },
  ],
});

export default router;
