import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueRouter from 'vue-router';
import colors from 'vuetify/lib/util/colors';

import tr from 'vuetify/es5/locale/tr';

Vue.use(Vuetify);
Vue.use(VueRouter);

export default new Vuetify({
  lang: {
    locales: {
      tr,
    },
    current: 'tr',
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: colors.purple,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
      dark: {},
    },
    dark: true,
  },
});
